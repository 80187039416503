window.addEventListener('load', () => handleCollapse());

const handleCollapse = (): void => {
  Array.from(document.getElementsByClassName('collapse-trigger')).forEach((collapseTrigger: HTMLElement) => {
    collapseTrigger.addEventListener('click', () => toggleCollapse(collapseTrigger.parentElement));

    collapseTrigger.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key !== 'Enter') {
        return;
      }

      toggleCollapse(collapseTrigger.parentElement);
    });
  });
};

const toggleCollapse = (collapseWrapper: HTMLElement): void => {
  const active: boolean = collapseWrapper.classList.contains('collapse-active');

  Array.from(document.getElementsByClassName('collapse-wrapper')).forEach((collapseWrapper: HTMLElement) => closeCollapse(collapseWrapper));

  if (!active) {
    openCollapse(collapseWrapper);
  }
};

const closeCollapse = (collapseWrapper: HTMLElement): void => {
  const collapseContent: HTMLElement = collapseWrapper.getElementsByClassName('collapse-content').item(0) as HTMLElement;
  collapseContent.style.maxHeight = null;
  collapseWrapper.classList.remove('collapse-active');
};

const openCollapse = (collapseWrapper: HTMLElement): void => {
  const collapseContent: HTMLElement = collapseWrapper.getElementsByClassName('collapse-content').item(0) as HTMLElement;
  collapseContent.style.maxHeight = `${collapseContent.scrollHeight}px`;
  collapseWrapper.classList.add('collapse-active');
};
